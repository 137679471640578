/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import SpotifyPlayer from "./SpotifyPlayer";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Here will a React component go:"), "\n", React.createElement(SpotifyPlayer, {
    uri: "spotify:user:bbcamerica:playlist:3w18u69NplCpXVG4fQG726",
    size: "large",
    theme: "black",
    view: "list"
  }), "\n", React.createElement(_components.p, null, "Here will a normal code block go:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "(function() {\n\nvar cache = {};\nvar form = $('form');\nvar minified = true;\n\nvar dependencies = {};\n\nvar treeURL = 'https://api.github.com/repos/PrismJS/prism/git/trees/gh-pages?recursive=1';\nvar treePromise = new Promise(function(resolve) {\n  $u.xhr({\n    url: treeURL,\n    callback: function(xhr) {\n      if (xhr.status < 400) {\n        resolve(JSON.parse(xhr.responseText).tree);\n      }\n    }\n  });\n});\n")), "\n", React.createElement(_components.p, null, "A code block with a JSDoc comment, short, and long comment:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "/**\n * Get value out of string (e.g. rem => px)\n * If value is px strip the px part\n * If the input is already a number only return that value\n * @param {string | number} input\n * @param {number} [rootFontSize]\n * @return {number} Number without last three characters\n * @example removeLastThree('6rem') => 6\n */\nconst getValue = (input, rootFontSize = 16) => {\n  if (typeof input === `number`) {\n    return input / rootFontSize;\n  }\n\n  const isPxValue = input.slice(-2) === `px`;\n\n  if (isPxValue) {\n    return parseFloat(input.slice(0, -2));\n  }\n\n  return parseFloat(input.slice(0, -3));\n};\n\n// This is a little helper function\nconst helper = (a, b) => a + b;\n\n// This is also a little helper function but this time with a really long one-line comment that should show some more details\nconst morehelper = (a, b) => a * b;\n\nexport { getValue, helper, morehelper };\n")), "\n", React.createElement(_components.p, null, "Normal block without language:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "import Test from \"../components/test\"\n\nconst Layout = ({ children }) => (\n  <Test>\n    {children}\n  </Test>\n)\n\nexport default Layout\n")), "\n", React.createElement(_components.p, null, "Code block with code highlighting:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    title: "src/components/post.jsx",
    highlight: "5-7,10"
  }, "import * as React from \"react\";\n\nconst Post = ({ data: { post } }) => (\n  <Layout>\n    <Heading variant=\"h2\" as=\"h2\">\n      {post.title}\n    </Heading>\n    <p\n      sx={{\n        color: `secondary`,\n        mt: 3,\n        a: { color: `secondary` },\n        fontSize: [1, 1, 2],\n      }}\n    >\n      <span>{post.date}</span>\n      {post.tags && (\n        <React.Fragment>\n          {` — `}\n          <ItemTags tags={post.tags} />\n        </React.Fragment>\n      )}\n    </p>\n    <section\n      sx={{\n        ...CodeStyles,\n        my: 5,\n        \".gatsby-resp-image-wrapper\": { my: 5, boxShadow: `lg` },\n      }}\n    >\n      <MDXRenderer>{post.body}</MDXRenderer>\n    </section>\n  </Layout>\n);\n\nexport default Post;\n")), "\n", React.createElement(_components.p, null, "Code block without title:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "Harry Potter and the Philosopher's Stone\n")), "\n", React.createElement(_components.p, null, "Code block with lineNumbers (and lang):"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-text",
    withLineNumbers: true
  }, "Harry Potter and the Chamber of Secrets\n")), "\n", React.createElement(_components.p, null, "Code block with lineNumbers (and without lang):"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-none",
    withLineNumbers: true
  }, "Harry Potter and the Chamber of Secrets\n")), "\n", React.createElement(_components.p, null, "Code block with only the title:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-none",
    title: "src/utils/scream.js"
  }, "const scream = (input) => window.alert(input)\n")), "\n", React.createElement(_components.p, null, "Code block with only the title and with lineNumbers:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-none",
    title: "src/utils/scream.js",
    withLineNumbers: true
  }, "const scream = (input) => window.alert(input)\n")), "\n", React.createElement(_components.p, null, "Line highlighting without code title:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    highlight: "2,4-5"
  }, "const test = 3;\nconst foo = \"bar\";\nconst harry = \"potter\";\nconst hermione = \"granger\";\nconst ron = \"weasley\";\n")), "\n", React.createElement(_components.p, null, "Here will ", React.createElement(_components.code, null, "inline code"), " go, just inside the text. Wow!"), "\n", React.createElement(_components.p, null, "Code block with line numbers, highlighting, language, and title:"), "\n", React.createElement("div", {
    "data-testid": "code-block"
  }, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx",
    title: "src/components/blog.tsx",
    highlight: "7-9,16",
    withLineNumbers: true
  }, "import * as React from \"react\";\n\nconst Blog = ({ posts }: PostsProps) => {\n  const { tagsPath, basePath } = useSiteMetadata();\n\n  return (\n    <Layout>\n      <Flex sx={{ alignItems: `center`, justifyContent: `space-between` }}>\n        <Heading variant=\"h2\" as=\"h2\">\n          Blog\n        </Heading>\n        <Styled.a\n          as={Link}\n          sx={{ variant: `links.secondary` }}\n          to={`/${basePath}/${tagsPath}`.replace(/\\/\\/+/g, `/`)}\n        >\n          View all tags\n        </Styled.a>\n      </Flex>\n      <Listing posts={posts} sx={{ mt: [4, 5] }} />\n    </Layout>\n  );\n};\n\nexport default Blog;\n"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
